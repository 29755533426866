<template>
  <div>
    <div class="flex justify-start items-end">
      <div class="md:w-6/12">
        <img
          class="h-20 ml-28 mt-8 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="titlemenu1"
          :to1="'/petrecord'"
          titlemenu2="Health Log"
          :to2="'/healthlogoverview'"
          titlemenu3="Visualized Health"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex justify-center">
      <ul
        class="w-10/12 px-4 bg-primary-20 font-proximaLight text-subheaderText"
      >
        <li class="w-full my-4 flex bg-white relative" v-if="health1">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health1[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health1"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health2">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health2[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health2"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health3">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health3[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health3"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health4">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health4[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health4"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health5">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health5[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health5"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health6">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health6[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health6"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health7">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health7[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health7"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health8">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health8[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health8"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health9">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health9[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health9"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health10">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health10[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health10"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    poopDesc(data.other_significance)
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health11">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health11[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health11"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li class="w-full my-4 flex bg-white relative" v-if="health12">
          <div class="-rotate-90 text-sm absolute -left-5" style="top: 66px">
            {{ getDate(health12[0].date_created) }}
          </div>
          <div class="w-full py-4 pl-12">
            <ul class="w-full flex items-end">
              <li
                class="w-3/12 flex flex-col items-center justify-between"
                v-for="(data, i) in health12"
                :key="i"
              >
                <div class="flex">
                  <img
                    v-if="data.side_view"
                    class="h-16"
                    :key="i + 55"
                    :src="data.side_view"
                    :alt="data.id"
                  />
                  <img
                    v-if="data.top_view"
                    class="h-16"
                    :key="i"
                    :src="data.top_view"
                    :alt="data.id"
                  />
                </div>
                <div class="w-full pl-16 flex items-center justify-start">
                  <img :src="poopImg(data.poop)" :alt="data.id" />
                  <span class="text-sm pl-2">{{
                    data.other_significance
                  }}</span>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { nameOfPet } from "../../../methods/petMethods";

export default {
  components: {
    HeaderGlobal,
  },
  data() {
    return {
      pet: {},
      titlemenu1: "",
      petData: [
        {
          id: 1,
          date: "January 2023",
          feces: [
            {
              color: "blacktarry",
              details: "soft / watery",
              dogimg: [],
            },
            {
              color: "blacktarry",
              details: "mucus coating",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "brown",
              details: "hard pebbles",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "green",
              details: "large size",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
          ],
        },
        {
          id: 2,
          date: "December 2022",
          feces: [
            {
              color: "grey",
              details: "soft / watery",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "orangeyellow",
              details: "mucus coating",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "redstreaks",
              details: "hard pebbles",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "whitespot",
              details: "large size",
              dogimg: ["dog-sideview.png"],
            },
          ],
        },
        {
          id: 3,
          date: "November 2022",
          feces: [
            {
              color: "grey",
              details: "soft / watery",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "orangeyellow",
              details: "mucus coating",
              dogimg: ["dog-sideview.png"],
            },
            {
              color: "redstreaks",
              details: "hard pebbles",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "whitespot",
              details: "large size",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
          ],
        },
        {
          id: 4,
          date: "October 2022",
          feces: [
            {
              color: "grey",
              details: "soft / watery",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "orangeyellow",
              details: "mucus coating",
              dogimg: ["dog-birdview.png"],
            },
            {
              color: "redstreaks",
              details: "hard pebbles",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
            {
              color: "whitespot",
              details: "large size",
              dogimg: ["dog-birdview.png", "dog-sideview.png"],
            },
          ],
        },
      ],
      title1: "",
    };
  },

  computed: {
    ...mapGetters({
      healths: "healthlog/getHealths",
    }),
    health1() {
      console.log(this.healths);
      return this.healths ? this.healths["1"] : null;
    },
    health2() {
      return this.healths ? this.healths["2"] : null;
    },
    health3() {
      return this.healths ? this.healths["3"] : null;
    },
    health4() {
      return this.healths ? this.healths["4"] : null;
    },
    health5() {
      return this.healths ? this.healths["5"] : null;
    },
    health6() {
      return this.healths ? this.healths["6"] : null;
    },
    health7() {
      return this.healths ? this.healths["7"] : null;
    },
    health8() {
      return this.healths ? this.healths["8"] : null;
    },
    health9() {
      return this.healths ? this.healths["9"] : null;
    },
    health10() {
      return this.healths ? this.healths["10"] : null;
    },
    health11() {
      return this.healths ? this.healths["11"] : null;
    },
    health12() {
      return this.healths ? this.healths["12"] : null;
    },
  },

  created() {
    const petObject = localStorage.getItem("pet");
    const pet = JSON.parse(petObject);
    this.pet = pet;

    this.titlemenu1 = nameOfPet(pet.species) + " record: " + pet.callName;
    this.getHealthVisualized(pet.petID);
    //this.getHealthVisualized("477bc1f2-f028-4cdd-9968-d2f2cdf21d55");
  },

  methods: {
    ...mapActions({
      getHealthVisualized: "healthlog/getHealthVisualized",
    }),
    getDate(day) {
      return moment(String(day)).format("MMMM YYYY");
    },

    poopDesc(i) {
      return i === "soft_watery"
        ? "Soft / watery"
        : "hard_pebbles"
        ? "Hard / pebbles"
        : "mucus_coating"
        ? "Mucus coating"
        : "Large size";
    },
    poopImg(i) {
      if (i == "brown") return require(`@/assets/images/feces/brown.png`);
      if (i == "black") return require(`@/assets/images/feces/blacktarry.png`);
      if (i == "green") return require(`@/assets/images/feces/green.png`);
      if (i == "orange")
        return require(`@/assets/images/feces/orangeyellow.png`);
      if (i == "white_spots")
        return require(`@/assets/images/feces/whitespot.png`);
      if (i == "red_streaks")
        return require(`@/assets/images/feces/redstreaks.png`);
      if (i == "grey_greasy") return require(`@/assets/images/feces/grey.png`);
    },
  },
};
</script>



<style>
.-rotate-90 {
  transform: rotate(-90deg);
}
</style>