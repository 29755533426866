<template>
  <div class="my-6">
    <VisualizedHealthSection />
  </div>
</template>

<script>
import VisualizedHealthSection from "../components/healthlogoverview/visualizedhealth/VisualizedHealthSection.vue";

export default {
  components: {
    VisualizedHealthSection,
  },
};
</script>